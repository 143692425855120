<template>
  <div class="support-container">
    <SectionTitle title="Liên hệ" />
    <div class="support">
      <div
        v-for="item in SUPPORT_MENU"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="item?.type"
        :to="item.url"
      >
        <div v-if="item.isLiveChat" class="support__item--link" @click="$openLiveChat()">
          <CommonBaseImg v-if="!isBotChromeLighthouse" class="icon icon-guide" :src="item.iconMB" :alt="item.name" />
          <div class="text">{{ item.name }}</div>
        </div>
        <div v-else>
          <NuxtLink :to="item.url || '#'" target="_blank" class="support__item--link">
            <CommonBaseImg v-if="!isBotChromeLighthouse" class="icon" :src="item.iconMB" :alt="item.name" />
            <p class="text">{{ item.name }}</p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CommonBaseImg from '~~/components/common/base-img.vue'
import { useAppStore } from '~/store/app'
import { supportMenu } from '~/constants/menu'
import SectionTitle from '~/components/mobile/common/section-title.vue'
import { useContact } from '~~/composables/useContact'
const { isBotChromeLighthouse } = useAppStore()

const SUPPORT_MENU = supportMenu()
const { fetchContact } = useContact()
useAsyncData(async () => {
  await fetchContact()
})

</script>
<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-contact/index.scss"></style>
