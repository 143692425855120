<template>
  <div
    :id="item.name"
    class="banner-item"
  >
    <CommonBaseImg
      :id="`${item?.name}-image`"
      :src="item.img"
      :alt="item?.name"
      lazy
    />
  </div>
</template>
<script setup>
import CommonBaseImg from '@/components/common/base-img'
const { $device } = useNuxtApp()

defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
</script>
<style scoped src="./index.scss" />
