<template>
  <section id="games" class="section-top-play">
    <SectionTitle :title="TITLE_SECTION.TOP_PLAY" />
    <div class="section-top-play__wrapper">
      <div :class="`section-top-play__content ${isLoading ? 'loading' : ''}`">
        <div v-if="listGames?.length !== 0" class="section-top-play__content--list">
          <CommonGamesItemGame
            v-for="(item, index) in listGames.slice(0, 9)"
            :key="index"
            :item="item"
            class="mobile"
            :show-coin="false"
            :is-play-now="false"
            :radius="'4'"
          />
        </div>
        <CommonBaseEmpty
          v-else-if="loadPage && listGames?.length === 0"
          :empty-data="EMPTY_CONTENT[0]"
          :class="'mobile'"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import queryString from 'query-string'
import CommonBaseEmpty from '~/components/common/base-empty'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { TITLE_SECTION } from '@/constants/home'
import { EMPTY_CONTENT } from '@/constants/games/lobby'
import { useGame } from '~/composables/game/useGame'
import SectionTitle from '~/components/mobile/common/section-title.vue'

const emit = defineEmits(['onLoadingGame'])

const { fetchGames } = useGame()
const listGames = ref([])
const isLoading = ref(true)

const fetchGameQuaySo = async () => {
  try {
    isLoading.value = true
    const queryUrl = queryString.stringify({
      path_alias: 'all',
      provider_code: '',
      sort_by: 'TOP_USER_BET',
      limit: 6,
      page: 1
    })
    const { data: response } = await fetchGames(queryUrl)
    if (response.status === 'OK') {
      listGames.value = response.data?.items
    }
  } catch (error) {
    console.error('fetchTopBetGames', error)
  } finally {
    isLoading.value = false
  }
}

watch(isLoading, () => {
  emit('onLoadingGame', isLoading.value)
})

const loadPage = ref(false)
setTimeout(() => {
  loadPage.value = true
}, 500)
useAsyncData(async () => {
  await fetchGameQuaySo()
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-top-play/index.scss"></style>
