import { useAppStore } from '~/store/app'
import { seoApi } from '~/api/seo'

export const useContact = () => {
  const { $axios, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { BRAND_CONTACT } = seoApi()
  const contactList = ref<any>([])
  const fetchContact = async () => {
    try {
      const { data: response } = await $axios.get(BRAND_CONTACT)
      if (response && response.status === 'OK') {
        if (response.data?.length > 0) {
          contactList.value = response.data
          store.setContactData(contactList.value)
        }
      }
    } catch (error: any) {
      console.log('useContact', error?.response?.data?.message)
    }
  }

  return {
    fetchContact
  }
}
