import { PATH_GAME_MB_URL, PATH_IMAGES } from '~/constants/path'

export const EMPTY_CONTENT = [
  {
    icon: `${PATH_IMAGES}icon-empty.webp`,
    text: 'Games đang cập nhật, Quý khách vui lòng quay lại sau !'
  }
]

export const TEXT_CONTENT = {
  GAME_CATEGORY: 'Thể loại',
  GAME_SUGGEST: 'Đề xuất',
  GAME_FAVORITE: 'Yêu thích',
  VIEW_ALL: 'Xem tất cả',
  PLAY_NOW: 'Chơi ngay',
  RANDOM: 'Trò Chơi Ngẫu nhiên'
}

export const IMAGE_CONTENT = {
  COIN: 'images/icon/icon-coin.webp',
  ICON_GAME_TITLE: `${PATH_GAME_MB_URL}icon-game-title.svg`
}
