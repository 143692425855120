<template>
  <div class="section-title">
    <div class="section-title__container">
      <div style="display: flex;align-items: center;">
        <BaseImg v-if="iconSrc" class="section-title__container__icon" :src="iconSrc" />
        <div class="section-title__container__content-column">
          <div class="section-title__container__title">
            {{ title }}
          </div>
          <div v-if="description" class="section-title__container__description">
            {{ description }}
          </div>
        </div>
      </div>
      <div v-if="isSeeMore" class="section-title__container__see-more" @click="openSeeMore">Xem Tất cả</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import BaseImg from '~/components/common/base-img.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  iconSrc: {
    type: String,
    default: ''
  },
  link: {
    type: String,
    default: ''
  },
  isSeeMore: {
    type: Boolean,
    default: false
  }
})
const router = useRouter()
const openSeeMore = () => {
  if (props?.link) {
    router.push(props.link)
  }
}
</script>
<style lang="scss" scoped src="assets/scss/components/common/section-title.scss"></style>
