<template>
  <div class="animated-number">
    <span v-if="showAnimation" class="animated-number__text">{{ $formatNumberWithCommas(displayNumber, ',') +' K' }}</span>
    <span v-else class="animated-number__text">
      {{ $formatNumberWithCommas(number, ',') +' K' }}
    </span>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    number: number | string
    fromNumber?: number
    showCoin?: boolean
    showAnimation?: boolean
  }>(),
  {
    number: 0,
    fromNumber: 0,
    showAnimation: true,
    showCoin: false
  }
)
const { $formatNumberWithCommas } = useNuxtApp()

const displayNumber = ref<number>(props.fromNumber ? props.fromNumber : 0)
const interval = ref<number | undefined>(undefined)

const watchNumber = () => {
  clearInterval(interval.value)

  if (props.number === displayNumber.value) {
    return
  }

  interval.value = setInterval(() => {
    if (displayNumber.value !== props.number) {
      let change = (props.number - displayNumber.value) / 10
      change = change >= 0 ? Math.ceil(change) : Math.floor(change)
      displayNumber.value = displayNumber.value + change
    }
  }, 50)
}
watchNumber()

watch(
  () => props.number,
  () => {
    watchNumber()
  }
)
</script>

<style lang="scss" scoped src="assets/scss/components/common/animated-number.scss"></style>
